import * as React from 'react'
import { Link as GatsbyLink } from 'gatsby'

import { Button, Center, Heading, Text } from '@chakra-ui/react'

import { Layout, BodySection, Hero } from '../components/PageLayout'
import SEO from '../components/SEO'
import { PlanBreakdown } from '../components/PlanBreakdown'

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Welcome" description="Connecting David's home neighbourhood with 4G/LTE." />

      <Hero title="Connecting David's home with cellular" text="Providing cellular connection throughout his neighbourhood." />

      <BodySection darker>
        <Heading mb={2}>Coverage map</Heading>
        <Text>
          View our full coverage area on our interactive map. We'll show you where you can expect signal, and approximate speeds for
          key&nbsp;locations.
        </Text>

        <Center marginTop={4}>
          <Button variant="outline" colorScheme="pink" as={GatsbyLink} to="/coverage-map">
            View coverage map
          </Button>
        </Center>
      </BodySection>

      <BodySection>
        <Heading mb={2}>Choose your plan</Heading>
        <Text>DavMobile offers one plan and one plan only!</Text>

        <PlanBreakdown />
      </BodySection>
    </Layout>
  )
}

export default IndexPage
